<template>
	<ion-page>
		<ion-header>
			<ion-toolbar>
				<ion-back-button text="" slot="start" :defaultHref="{name: 'home'}" />

				<ion-title>
					<ion-label v-if="course">{{ course.name }}</ion-label>
				</ion-title>

				<div slot="end" class="user-wrapper" @click="$router.push(isLoggedIn ? `/profile/${user.id}` : '/login')">
					<p class="web-version">{{ isLoggedIn ? 'Profil' : 'Prihlásiť sa' }}</p>
					<ion-icon :icon="isLoggedIn ? personCircleOutline : logInOutline"></ion-icon>
				</div>
			</ion-toolbar>
		</ion-header>

		<ion-content class="ion-no-padding">

			<w-loadable :items="course" skelet="spinner">
				<div class="course-information-wrapper">
					<div class="container py-1 d-flex ion-justify-content-center ion-align-items-center">
						<img v-if="course.image?.path" class="course-img margin-auto mt-1" :src="course.image?.path">
						<img v-else class="course-img margin-auto mt-1" src="../themes/img/placeholder-img.jpg">
					</div>
					
					<hr>

					<div class="container px-1">
						<h4 class="large">{{ course.name }}</h4>
					</div>
				</div>

				<w-loadable class="container" :items="lessons" skelet="spinner" messageEmpty="Aktuáľnemu hľadaniu nevyhovujú žiadné témy">
					<ion-row>
						<ion-col v-for="(category, i) in lessons" :key="category.group_name" size="12">
							<ion-card>
								<h4 class="mb-1">{{i + 1}}. {{ category.group_name }}</h4>
								<ion-item lines="none" v-for="(lesson, j) in category.group_lessons" :key="lesson.id" @click="$router.push({name: 'legacy-course', params: {lessonSlug: lesson.slug, courseSlug: course.slug}})">
									<p class="large">{{i + 1}}. {{j + 1}} {{ lesson.name }}</p>
								</ion-item>
							</ion-card>
						</ion-col>
					</ion-row>
				</w-loadable>

			</w-loadable>

		</ion-content>
	</ion-page>
</template>

<script>
// import ACourseCard 		from '@/plugins/lib@enum/theme/components/a-course-card.vue'
// import AVideosThumb 	from '@/plugins/lib@enum/theme/components/a-videos-thumb.vue'
// import ATiktokFeed 		from '@/plugins/lib@feed/_components/a-feed.vue'
import wAxios 			from '@/plugins/w/axios'
import { mapGetters} 	from 'vuex'
import { personCircleOutline, schoolOutline, checkmarkDoneCircleOutline, logInOutline } from 'ionicons/icons'

const api = {
	getCourse: (slug) => wAxios.get_auth_data(`https://open-academy.sk/cms/api/courses/slug/?q=${slug}`),
}

export default {
	// components: {
	// 	ACourseCard,
	// 	AVideosThumb,
	// 	ATiktokFeed
	// },

	data() {
		return {
			personCircleOutline,
			// schoolOutline,
			// checkmarkDoneCircleOutline,
			logInOutline,
			activeTab: 'Projekty',
			course: null,
			lessons: null,
			// stats: {
			// 	pending: 0,
			// 	verified: 0
			// }
		}
	},

	computed: {
		...mapGetters('wAuth', [
			'isLoggedIn',
			'user'
		]),
	},

	// created() {
	// 	this.eventBus.on('course:update', async (course) => {
	// 		let targetCourse = this.theme.courses.find(c => c.id == course.id)
	// 		targetCourse = course
	// 	})
	// },

	async ionViewWillEnter() {
		this.course	= null
		// this.videos = null

		console.log(this.$route.params.themeSlug)

		try {
			this.course 	= await api.getCourse(this.$route.params.themeSlug)
			this.lessons = this.course.lessons
			console.log(this.course)
			console.log(this.lessons)
			// if (this.isLoggedIn) this.stats = await api.confirmUser(this.theme.id, this.user.id)
		} catch (error) {
			this.course 	= {error: error}
		}
	}
}
</script>

<style lang="sass" scoped>

.course-information-wrapper
	background-color: white
	padding: 0 20px 15px
	cursor: default

	.course-img
		height: 110px
		max-height: 20vh

	hr
		margin-bottom: 5px

	h4
		margin: 10px 0 5px

	// .status-wrapper
	// 	max-width: 350px
	// 	margin: auto

	// 	ion-icon
	// 		margin-right: 5px

	// 	p
	// 		line-height: 15px
	// 		color: black


ion-col
	padding: 10px 0 0

ion-card
	height: 100%
	cursor: default !important
	border-radius: 0


	ion-item
		--background: rgba(63, 74, 215, .05)
		--border-radius: 50px
		--padding-start: 15px
		--padding-end: 15px
		margin: 5px 0 0
		cursor: pointer

		p
			margin: 0

</style>